import * as React from 'react';
import { useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby-plugin-react-intl';
import { gsap } from 'gsap';

import * as styles from './project-thumbnail.module.scss';

const ProjectThumbnail = ({ project, className }) => {
	const gsapOverlayTimeline = useRef(null);
	const thumbnailEl = useRef(null);
	const thumbnail = project?.gallery[0];

	useLayoutEffect(() => {
		if (!window?.matchMedia('(hover: hover)')?.matches) {
			gsapOverlayTimeline.current = gsap.timeline({
				scrollTrigger: {
					trigger: thumbnailEl?.current,
					start: 'top center+=100',
					end: 'bottom center+=75',
					toggleClass: { targets: thumbnailEl?.current, className: styles.overlayVisible },
				},
			});
		}

		return () => {
			gsapOverlayTimeline.current?.kill();
		};
	}, [thumbnailEl]);

	return (
		<div ref={thumbnailEl} className={`${styles.projectThumbnailWrapper} ${className}`}>
			<Link
				to={`/project/${project?.slug}`}
				className={styles.projectThumbnail}
				aria-label={project?.title}
			>
				<GatsbyImage
					alt={thumbnail?.meta?.alt ?? ''}
					image={getImage(thumbnail?.file)}
					className={styles.thumbnail}
				/>

				<div className={styles.overlay}>
					<h3>{project.title}</h3>
				</div>
			</Link>
		</div>
	);
};

ProjectThumbnail.defaultProps = {
	className: '',
	project: null,
};

ProjectThumbnail.propTypes = {
	className: PropTypes.string,
	project: PropTypes.shape({
		title: PropTypes.string.isRequired,
		slug: PropTypes.string.isRequired,
		gallery: PropTypes.arrayOf(
			PropTypes.shape({
				meta: PropTypes.object,
				file: PropTypes.object.isRequired,
			})
		),
	}).isRequired,
};

export default ProjectThumbnail;
