import * as React from 'react';
import { useMemo, useState } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import MainLayout from '../layouts/main-layout/main-layout';
import { graphql } from 'gatsby';
import ParsedContent from '../components/parsed-content/parsed-content';
import ProjectList from '../components/project-list/project-list';
import { PROJECTS_PER_PAGE } from '../config';

import * as styles from '../styles/pages/projects.module.scss';

const ProjectsPage = ({ data }) => {
	const intl = useIntl();

	const [maxVisibleProjects, setMaxVisibleProjects] = useState(PROJECTS_PER_PAGE);
	const visibleProjects = useMemo(() => {
		return data?.api?.projects?.slice(0, maxVisibleProjects);
	}, [data?.api?.projects, maxVisibleProjects]);

	return (
		<MainLayout
			seo={{
				title: intl.formatMessage({
					id: 'projects-page-title',
					defaultMessage: 'Projects',
				}),
				description: data?.api?.seo?.siteDescription,
			}}
		>
			<section className={styles.projectsSection}>
				<h1 className="lower">
					{intl.formatMessage({
						id: 'projects-page-projects-section-title',
						defaultMessage: 'Projects',
					})}
				</h1>

				<ProjectList projects={visibleProjects} />

				{data?.api?.projects?.length > visibleProjects?.length && (
					<button
						className={`btn-border-dark ${styles.moreBtn}`}
						onClick={() => setMaxVisibleProjects(maxVisibleProjects + PROJECTS_PER_PAGE)}
					>
						{intl.formatMessage({
							id: 'projects-page-more-projects-btn',
							defaultMessage: 'Show more',
						})}
					</button>
				)}
			</section>

			{(data?.api?.references?.referencesLeft?.length > 0 ||
				data?.api?.references?.referencesRight?.length > 0) && (
				<section className={styles.referencesSection}>
					<h2 className="lower">
						{intl.formatMessage({
							id: 'projects-page-references-section-title',
							defaultMessage: 'References',
						})}
					</h2>

					<div className={styles.references}>
						<div className={styles.flexContainer}>
							<ParsedContent
								html={data?.api?.references?.referencesLeft}
								className={styles.column}
							/>

							<ParsedContent
								html={data?.api?.references?.referencesRight}
								className={styles.column}
							/>
						</div>
					</div>
				</section>
			)}
		</MainLayout>
	);
};

export const query = graphql`
	query($language: String!) {
		api {
			seo: seoSingleton(lang: $language) {
				siteDescription
			}

			projects: projectsCollection(lang: $language, sort: { _o: 1 }) {
				title
				slug
				gallery {
					path
					meta
					file {
						childImageSharp {
							gatsbyImageData(layout: FULL_WIDTH)
						}
					}
				}
			}

			references: referencesSingleton(lang: $language) {
				referencesLeft
				referencesRight
			}
		}
	}
`;

export default ProjectsPage;
