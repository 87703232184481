import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ProjectThumbnail from '../project-thumbnail/project-thumbnail';

import * as styles from './project-list.module.scss';

const ProjectList = ({ singleRow, projects }) => {
	return (
		<div
			className={classNames({
				[styles.projectList]: true,
				[styles.singleRow]: singleRow,
			})}
		>
			<div className={styles.flexContainer}>
				{projects?.map((project, i) => (
					<ProjectThumbnail
						key={`projectListThumb${i}`}
						project={project}
						className={styles.projectThumbnail}
					/>
				))}
			</div>
		</div>
	);
};

ProjectList.defaultProps = {
	singleRow: false,
	projects: [],
};

ProjectList.propTypes = {
	singleRow: PropTypes.bool,
	projects: PropTypes.arrayOf(ProjectThumbnail.propTypes.project),
};

export default ProjectList;
